import useSWR from 'swr';

import { Pack, Packs } from '@/components/app/cards/PacksList/types';
import { Tag, Tags } from '@/components/app/cards/TagsList/types';
import { useApi } from '@/hooks/api';
import { useUserState } from '@/hooks/store';

export interface PacksFeedResponse {
	feed: {
		id: string;
		title: string;
		tags: Tags;
		packs: Pack[];
		subtitle?: string;
	};
}

interface PacksFeedReturn {
	isLoading: boolean;
	packs: Packs;
	tags: Tag[];
	title?: string;
	subtitle?: string;
}

export function usePacksFeed(packFeedName?: string | null): PacksFeedReturn {
	const apiRequest = useApi();
	const user = useUserState();

	const { data, isValidating } = useSWR<PacksFeedResponse>(
		[packFeedName ? `packs/feeds/${packFeedName}` : null, packFeedName, user?.id],
		async ([endpoint]: [endpoint: string]) => {
			const res = await apiRequest<PacksFeedResponse>({
				endpoint,
				method: 'GET',
				customHeaders: {
					'x-device-capabilities': 'v1;signedCookie;streamOnly',
				},
			});
			return res.data;
		},
		{ revalidateOnFocus: false, errorRetryCount: 0 },
	);

	return {
		isLoading: isValidating,
		packs: data?.feed.packs ?? [],
		tags: data?.feed.tags ?? [],
		title: data?.feed?.title,
		subtitle: data?.feed?.subtitle,
	};
}
