import { GetServerSideProps } from 'next';

import PacksList from '@/components/app/cards/PacksList';
import Layout from '@/components/layout/LayoutWrapper';
import { usePacksFeed } from '@/hooks/app/usePacksFeed';
import { getInitialServerState } from '@/utils/pageGeneration/getServerSideProps';

const App = () => {
	const { packs, isLoading } = usePacksFeed('home-web');

	return (
		<Layout pageName="app">
			<PacksList showGreeting={true} packs={packs} isLoading={isLoading} />
		</Layout>
	);
};

export default App;

export const getServerSideProps: GetServerSideProps = async context => {
	const initialState = await getInitialServerState(context);

	return {
		props: {
			appHeaderProps: {
				showGreeting: true,
				showScenesLink: true,
				showSearchLink: true,
			},
			initialState: {
				...initialState,
				theme: {
					webAppLayout: 'full',
				},
			},
		},
	};
};
